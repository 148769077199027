$psa-green: #368729;
$psa-light-green: #d8eed4;
$psa-light-grey: #f1f1f1;
$psa-black: #333333;
$psa-white: #ffffff;
$psa-border-color: #368729;
$psa-light-font: #707070;
$psa-orange: #CD4400;
$psa-font-color: #000000;
$psa-grey: #dddddd;
$psa-error: #e64c00;
$psa-hr: #c9d3de;

$psa-font-normal-size: 1rem;
$psa-font-xs-size: 0.75rem;

$psa-font-normal-weight: 400;
$psa-font-bold-weight: 600;
$psa-font-xbold-weight: 700;


.smx-3 {
    max-width: 300px;
}

.smx-8{
    max-width: 800px;
}

.a-white a {
    color: #{$psa-white};
    font-weight: #{$psa-font-bold-weight}
}

.c-pointer{
    cursor: pointer;
}
