@import './Root';

.pagination-container {
    display: flex;
    justify-content: space-between;

    .pagination-button {
        border: none;
        margin: 0 4px;
        padding: 5px 10px;
        box-shadow: 0px 2px 8px 0px #0000001F;
        opacity: none;
        background-color: #{$psa-white};
        width: 40px;
        height: 40px;
        border-radius: 4px;
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: #{$psa-green};

        &.active {
            background-color: #{$psa-green};
            box-shadow: none;
            color: white;
        }

        &.disabled-navigation-button{
            color: #ADADAD;
            background-color: #{$psa-white};
            box-shadow: none;
        }
    }
}