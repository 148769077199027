@import './Root';

#r-a {
    .s-p {
        min-height: 100px;

        h4.signup a {
            font-weight: 700;
            text-decoration: none;
            color: #368729;
            opacity: 0.87;
        }

        .icon-size {
            min-width: 24px;
            min-height: 24px;
        }

        .h-background {
            background-color: #{$psa-light-grey};
        }

        .s-f {

            form#search-widget {
                gap: 10px;

                .large-data {
                    .select-react__indicator.select-react__dropdown-indicator {
                        display: none;
                    }
                }
            }

            #find-product {
                flex-basis: 0%;
            }

            .btn {
                min-height: 44px;

                span {
                    white-space: nowrap;
                }
            }

            .offline-search {
                margin-bottom: 24px;

                b {
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }
            }
        }

        .s-r {
            min-height: 400px;

            .badge.bg-cabi {
                background-color: #{$psa-light-green};
            }

            .information-container {
                display: inline-flex;

                svg {
                    margin-right: 8px;
                }
            }

            .information {
                h4 {
                    a {
                        font-weight: #{$psa-font-xbold-weight};
                        text-decoration: none;
                        color: #{$psa-green};
                    }

                    a:hover {
                        opacity: 1;
                    }
                }
            }

            .s-r {
                padding-bottom: 50px;

                .missing-notice {
                    a {
                        color: #{$psa-green};
                    }
                }

                .basic-single {
                    display: inline-block;
                    min-width: 230px;
                }

                .filter {
                    display: flex;

                    .basic-multi {
                        min-width: 230px;
                    }

                    .basic-multi > div {
                        height: auto;
                    }
                }

                .result-card {
                    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                    cursor: pointer;

                    &.queued {
                        background: #ffd700;
                    }

                    * {
                        cursor: pointer;
                    }

                    .sponsored {
                        background: #{$psa-green};
                        min-height: 2.5rem;
                        border-radius: 0.5rem 0.5rem 0 0;

                        span {
                            color: #{$psa-white};
                        }
                    }

                    .category {
                        background-color: #{$psa-light-grey};
                        min-width: 18%;
                        aspect-ratio: 1 / 1;

                        img {
                            max-height: 60px;
                            aspect-ratio: 1 / 1;
                        }
                    }

                    .product-name {
                        color: #{$psa-green};
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3;
                        font-size: 18px !important;
                        align-self: baseline;
                    }

                    div {

                        label.category-label {
                            color: #{$psa-black};
                            font-size: 16px;
                        }

                        .active-ingredient {
                            list-style-type: disc;
                            list-style-position: outside;
                            color: #{$psa-black};
                            border-bottom: 1px solid #{$psa-black};
                            font-size: 16px;
                            padding-left: 1.5rem;
                            margin: 0;
                            width: 100%;

                            label {
                                font-weight: #{$psa-font-normal-weight};
                                color: #{$psa-black};
                            }

                            li {
                                overflow-y: clip;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                display: list-item; 
                                margin-left: 1.5rem; 
                            }
                        }

                        .company {
                            list-style: none;
                            color: #{$psa-light-font};

                            .registrant {
                                border-bottom: 2px solid #{$psa-light-grey};
                                font-size: 16px;
                            }

                            .manufacturer {
                                border-bottom: 2px solid #{$psa-light-grey};
                                font-size: 16px;
                            }

                            .distributors {
                                border-bottom: 2px solid #{$psa-light-grey};
                                font-size: 16px;

                                ul {
                                    list-style: none;

                                    label {
                                        color: #{$psa-light-font};
                                    }

                                    li::after {
                                        content: ", ";
                                    }

                                    li:last-child:after {
                                        content: "";
                                    }
                                }
                            }
                        }
                    }

                    .standards {
                        bottom: 1rem;

                        .endorsment {
                            img {
                                width: 60px;
                            }
                        }

                        .green,
                        .ambar {

                            img {
                                width: 42px;
                            }

                            svg {
                                left: 30px;
                                top: 25px;
                            }

                            div.LazyLoad {
                                padding: 0px !important;
                            }
                        }

                        .green {
                            span {
                                color: #{$psa-black};
                            }
                        }
                    }
                }

                .pagination-container {
                    margin-top: 40px;
                    display: flex;
                    justify-content: space-between;

                    .pagination-button {
                        margin: 0 2px;
                        padding: 5px 10px;
                        cursor: pointer;
                        border: none;

                        &.active {
                            background-color: green;
                            color: white;
                        }
                    }
                }


            }
        }

        .basic-single,
        .basic-multi {

            .select2-hidden-accessible {
                display: none !important;
            }

            div {
                border-radius: 0;
                font-weight: #{$psa-font-bold-weight};
            }

            .select-react__control {
                cursor: pointer;
            }

            .error .select-react__control {
                border: #{$psa-error} 3px solid;
            }

            .select-react__control {
                height: auto;
                min-height: 44px;
            }

            .select-react__control--is-focused.select-react__control--menu-is-open {
                .select-react__single-value {
                    display: none;
                }

                .select-react__placeholder {
                    display: none;
                }
            }

            .select-react__placeholder {
                color: #{$psa-black} !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }

            .select-react__indicator-separator {
                display: none;
            }

            .select-react__menu {
                border: 1px solid #aaa;
            }

            .select-react__indicator {
                svg {
                    color: #{$psa-black};
                }
            }

            .select-react__option {
                color: #{$psa-black};
            }

            .select-react__option.select-react__option--is-selected {
                background-color: #{$psa-grey} !important;
                color: #{$psa-black};
            }

            .select-react__option.select-react__option--is-focused {
                background-color: #{$psa-black} !important;
                color: #{$psa-white};
            }

            .react-select-react__option {
                background-color: #444;
            }
        }
    }
}

.bg-close {
    background: grey !important;
}

.moreInfo-btn {
    color: #368729;
    background-color: #ffffff;
    border: 1px solid #368729;
    font-weight: bolder;
    height: 100%;
    font-size: 16px;
}

.moreInfo-btn:hover {
    color: #ffffff !important;
    background-color: #368729 !important;
    border: 1px solid #368729 !important;
}

#r-a[dir="rtl"] {
    .s-f {
        .btn {
            svg {
                margin-right: 0 !important;
                margin-left: .25rem !important;
            }
        }
    }

    .s-r {
        .sortby {
            float: left !important;
        }

        .filter {
            float: right !important;

            .basic-single {
                float: right !important;
            }
        }

        .sortby {
            .basic-single {
                float: right !important;
            }
        }

        .result-card {
            h5 {
                text-align: right;
            }

            .sponsored {
                svg {
                    margin-left: 5px;
                }
            }

            .active-ingredient {
                text-align: right;

                li {
                    margin-left: 0px;
                    margin-right: 18px;
                }

                label {
                    display: block;
                }
            }

            .company {
                text-align: right;
            }
        }

        h3.result-count {
            text-align: right;
        }

        .information {
            text-align: right;
        }

        .standards {

            .green,
            .ambar {
                span {
                    margin-left: 0 !important;
                    margin-right: .25rem !important;
                }
            }
        }
    }

    label {
        text-align: right !important;
    }

    span {
        text-align: right !important;
    }

    h1 {
        text-align: right !important;
    }

    .basic-single,
    .basic-multi {
        &:first-child {
            margin-right: 0px;
        }


        .select-react__indicator.select-react__dropdown-indicator {
            width: 20px;
            margin-left: 10px;
        }

        .select-react__placeholder {
            text-align: right;
        }

        .select-react__single-value {
            text-align: right !important;
        }
    }

    .basic-single.select-react--is-rtl,
    .basic-multi.select-react--is-rtl {
        .select-react__option {
            text-align: right;
        }
    }
}

@media only screen and (max-width: 940px) {
    #r-a {
        .s-f {
            form {
                display: block;
            }
        }

        .s-r {
            .filter {
                width: 100%;
                display: block !important;
            }

            .sortby {
                width: 100%;
                float: left;
            }
        }
    }

    #r-a[dir="rtl"] {
        .s-f {
            .btn {
                margin-right: 0px;
            }
        }

        .basic-single,
        .basic-multi {
            margin-right: 0px;
        }
    }
}

@media only screen and (max-width: 768px) {
    #r-a {

        .s-p {
            .h-background {
                background-color: #{$psa-white};
            }
        }

        .s-f {
            text-align: left;
            display: block;
            width: auto;

            form {
                display: block !important;

                select {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                    margin-right: 0px;
                }
            }

            .btn {
                margin-left: auto;
                margin-right: auto;
                display: flex;
                align-items: center;
                -webkit-box-pack: center !important;
                -ms-flex-pack: center !important;
                justify-content: center !important;
            }
        }

        .s-r {
            .result-card {
                margin-top: 1rem !important;
            }
        }

        .basic-single,
        .basic-multi {
            width: 100%;
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 767px) {

    .active-ingredient {
        padding-left: 2.5rem;
      }
    .active-ingredient li {
      white-space: normal !important;
      overflow: visible !important;
      text-overflow: clip !important;
    }
  }