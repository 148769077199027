@import './Root';

#r-a {
    min-height: 100px;

    .o-switch {

        svg {
            width: 1rem;
            vertical-align: middle;
            margin-bottom: 3px;
        }
    }

    .p-d {

        div.signup {
            a {
                font-weight: 700;
                text-decoration: none;
                color: #368729;
                opacity: 0.87;
            }

            svg {
                min-width: 24px;
            }
        }

        .badge.bg-cabi {
            background-color: #{$psa-light-green};
        }

        .product-name {
            word-break: break-word;
        }

        .product-name-sub1 {
            font-size: #{$psa-font-normal-size};
            font-weight: #{$psa-font-xbold-weight};
            color: #{$psa-green};
            text-transform: uppercase;
        }

        .product-name-sub2 {
            font-size: #{$psa-font-normal-size};
            font-weight: #{$psa-font-normal-weight};
            color: #{$psa-green};
            text-transform: uppercase;
        }

        .product-std-fonts {
            font-size: #{$psa-font-normal-size};
            font-weight: #{$psa-font-normal-weight};
            color: #{$psa-black};
        }

        a.back-to-search-text {
            color: #{$psa-green};
        }

        a.back-to-search-text:hover {
            font-weight: bold;
        }

        .line-break {
            border-bottom: 2px solid #{$psa-green};
        }

        .p-info-btn {
            color: #{$psa-border-color};
            background-color: #{$psa-white};
            border: 1px solid #{$psa-border-color};
            cursor: pointer;

            div {
                white-space: nowrap;
            }

            svg {
                flex: 0 0 25px;

                path {
                    stroke: #{$psa-border-color};
                }
            }
        }

        .p-info-btn:not(.disabled):hover {
            color: #{$psa-white};
            background-color: #{$psa-border-color};
            border: 1px solid #{$psa-border-color};

            div {
                white-space: nowrap;
            }

            svg {
                flex: 0 0 25px;

                path {
                    stroke: #{$psa-white};
                }
            }
        }

        .p-info-btn.active {
            color: #{$psa-white};
            background-color: #{$psa-border-color};
            border: 1px solid #{$psa-border-color};

            div {
                white-space: nowrap;
            }

            svg {
                flex: 0 0 25px;

                path {
                    stroke: #{$psa-white};
                }
            }
        }

        .partner-product {
            height: 40px;
            background-color: #{$psa-green};

            .sponsored {
                display: block;
                background: #{$psa-green};
                min-height: 35px;
                border-radius: 10px 10px 0 0;

                svg {
                    vertical-align: middle;
                    margin-right: 5px;
                }

                span {
                    color: #{$psa-white};
                    font-weight: #{$psa-font-bold-weight};
                    vertical-align: middle;
                }
            }
        }

        .product-detail-header {
            display: flex;
            flex-direction: row;
        }

        .active-in {
            list-style-type: none;
        }

        .category-info {
            text-transform: uppercase;
            color: #{$psa-green};
        }

        .product-image {
            height: 180px;
            width: 180px;
            background-color: #{$psa-light-grey};

            div {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 100%;
                height: 100%;
            }
        }

        .flex-wrap-wrap {
            flex-wrap: wrap;
        }

        .crop-pest-heading {
            font-size: #{$psa-font-normal-size};
            font-weight: #{$psa-font-xbold-weight};
        }

        .wrapper-grey {
            min-height: 400px;
            padding-top: 30px;
            padding-bottom: 30px;

            ul.last-updated {
                display: flex;
                list-style: none;
                padding: 0px;
                float: right;

                li {
                    padding-left: 10px;
                }
            }

            div.details {

                .key-info-container {
                    margin: 12px 24px;
                    align-items: center;
                    height: 100%;
                }

                .manufacturer-list {
                    padding-inline-start: 0;
                    list-style-type: none;
                }
            }

            .title {
                padding: 24px;
            }

            div.distributor-card {

                .top-header {
                    background-color: #{$psa-light-grey};
                }

                address {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                }

                .address {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 20px;
                }
            }

            .grey-bg {
                background-color: #{$psa-light-grey};
                height: 100%;
            }

            div.detail-card {
                width: 100%;

                ul {
                    list-style: none;
                }

                .endorsment{
                    img{
                        width: 70px;
                    }
                }


                a.rectangle {
                    height: 48px;
                    padding: 8px;
                    border-radius: 5px;
                    box-shadow: 0 18px 20px 2px #d9e1e8;
                    display: inline-flex;
                    margin-bottom: 30px;
                    color: #{$psa-black};
                    text-decoration: none;
                    cursor: pointer;
                    align-items: center;
                    background-color: #{$psa-white};
                    border: 1px solid #{$psa-border-color};
                }

                .prodstd-image-size {
                    width: 50px;
                    padding-left: 0;
                }

                .url {
                    width: auto;
                    height: auto;
                    background-color: #{$psa-orange};
                    word-break: break-all;

                    svg {
                        margin-right: 10px;
                        vertical-align: middle;
                        width: 30px;
                    }

                    a {
                        color: #{$psa-white};
                        text-decoration: none;
                        word-break: break-word;
                    }
                }

                .tel-email {
                    ul {
                        display: flex;
                        list-style: none;
                        padding: 0px;
                        flex-direction: column;

                        li {

                            a {
                                word-break: break-all;
                                color: #000d1c;
                                text-decoration: none;
                                flex: 0 0 100%;
                            }

                            svg {
                                margin-right: 5px;
                                vertical-align: middle;
                                flex: 0 0 30px;
                            }
                        }
                    }
                }
            }

            div.product-info {
                background-color: #{$psa-white};
                padding-top: 0;
            }

            .std {

                .sh-icon {
                    flex: 0 0 50px;
                }

                span {
                    a {
                        font-weight: 700;
                        text-decoration: none;
                        color: #368729;
                        opacity: 0.87;
                    }
                }
            }

            .bullet-text {
                display: list-item;
                padding-bottom: 5px;
            }

            hr {
                width: 100%;
                height: 2px;
                background-color: #c9d3de;
                display: block;
                margin-top: 30px;
                margin-bottom: 40px;
            }
        }

        .treeview-wrapper {
            .treeview-col {
                display: inline-block;
                vertical-align: top;

                .tree-view_item {
                    span.node {
                        .info-icon {
                            width: 1.5em;
                            margin-left: 5px;
                            vertical-align: middle;
                        }
                    }
                }

                .tree-view_children {
                    display: grid;
                    grid-template-columns: auto auto auto;

                    span.node {
                        .info-icon {
                            width: 1.5em;
                            margin-left: 5px;
                            vertical-align: middle;
                        }
                    }
                }

                .tree-view {
                    overflow-y: hidden;
                    padding-bottom: 5px;
                }

                .tree-view_item {
                    svg {
                        width: 1.125em;
                        font-size: 12px;
                    }
                }

                .tree-view_children {
                    margin-left: 37px;
                    margin-top: -5px;
                }

                .tree-view_children-collapsed {
                    height: 0px;
                }

                .tree-view_arrow {
                    cursor: pointer;
                    display: inline-block;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    &:after {
                        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30"><path d="M10.5 15l7.5 7.5 7.5-7.5z"/><path d="M0 0h36v36h-36z" fill="none"/></svg>');
                        font-size: 25px;
                        vertical-align: middle;
                    }
                }

                .tree-view_arrow-collapsed {
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                }

                .tree-view_children {
                    svg {
                        width: 1.125em;
                        font-size: 12px;
                    }
                }

                .node-link .name {
                    text-decoration: underline;
                    cursor: pointer;
                    -moz-user-select: none;
                    -khtml-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    margin-bottom: 0px;
                }

                .node-link .name:hover {
                    color: #28a745;
                }
            }
        }
    }
}

#r-a[dir="rtl"] {
    .p-d {
        .wrapper-grey {
            label {
                text-align: right;
            }

            span {
                text-align: right;
            }

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: right;
            }

            a.backbtn {
                text-align: right;
                float: right;
            }

            div.detail-card {
                text-align: right;
            }

            .list {
                .card-header {
                    text-align: right;
                }
            }

            ul.last-updated {
                float: left;

                li {
                    padding-right: 10px;
                    padding-left: 0px;
                }
            }

            .treeview-wrapper {
                text-align: right;

                .treeview-col {
                    .tree-view_item {
                        span.node {
                            .info-icon {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .tree-view_children {
                margin-left: 0px;
                margin-right: 37px;
            }

            div.detail-card {
                .url {
                    svg {
                        margin-left: 5px;
                        margin-right: 0px;
                    }
                }

                .tel-email {
                    ul {
                        li {
                            &:first-child {
                                margin-right: 0px;
                            }

                            &:last-child {
                                margin-right: 20px;
                            }

                            svg {
                                margin-right: 0px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #r-a {
        .p-d {
            .wrapper-grey {
                ul.last-updated {
                    float: none !important;
                    margin-top: 10px;
                }

                .treeview-wrapper {
                    .treeview-col {
                        width: 100% !important;

                        .tree-view_children {
                            display: grid;
                            grid-template-columns: auto auto;
                        }
                    }
                }

                div.detail-card {
                    span.rectangle {
                        display: flex;
                        align-items: center;

                        svg {
                            min-width: 25px;
                        }
                    }

                    .tel-email {
                        ul {
                            display: block;

                            li {
                                margin-bottom: 10px;
                            }

                            &:first-child {
                                margin-right: 0px;
                            }

                            &:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }

                ul.details {
                    display: block;
                    margin-top: 0px;

                    &>li {
                        display: block;
                        width: 100% !important;
                        border-left: none !important;
                        border-bottom: 2px solid #{$psa-green};
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }

    #r-a[dir="rtl"] {
        .p-d {
            .wrapper-grey {
                div.detail-card {
                    .tel-email {
                        ul {
                            li:last-child {
                                margin-right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    #r-a {
        .p-d {
            .wrapper-grey {
                div.detail-card {
                    span.rectangle {
                        display: flex;
                        height: auto;
                        align-items: center;

                        svg {
                            min-width: 25px;
                        }
                    }

                    a.rectangle {
                        display: flex;
                        height: auto;
                        align-items: center;

                        svg {
                            min-width: 25px;
                        }
                    }

                    .product-detail-button-container {
                        flex-direction: column;
                    }

                    .product-detail-button {
                        width: 100%;

                        a {
                            width: 100%;
                            margin-right: 0 !important;
                        }

                        div {
                            width: 100%;
                            margin-right: 0 !important;
                        }
                    }
                }

                .product-image-name-container {
                    flex-direction: column !important;
                }

                .product-name-container {
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }

                .treeview-wrapper {
                    .treeview-col {
                        .tree-view_children {
                            display: grid;
                            grid-template-columns: auto;
                            max-height: 308px;
                            overflow-y: auto;
                        }
                    }
                }
            }

            .list {
                .card-header {
                    .form-row.toggle-crop-pest {
                        .col-auto {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }

            .product-image {
                width: 125px;
                height: 125px;
            }
        }
    }
}


@media (min-width: 577px) {
    .by-nc-sa-image {
        margin-top: -10px;
        width: 120px;
    }
}

@media (max-width: 577px) {
    .by-nc-sa-image {
        height: 38px;
        width: 100px;
    }
}